import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import RealEstate from './components/RealEstate';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tuition from './components/Tuition';
import AccountingServices from './components/AccountingServices';

function App() {
  // Force HTTP Redirection
  useEffect(() => {
    if (window.location.protocol === "http:") {
      const redirectUrl = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
      
      // Force the redirect to HTTPS, with a small delay
      setTimeout(() => {
        window.location.replace(redirectUrl);
      }, 100);
    }
  }, []);    

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/real-estate" element={<RealEstate />} />
          <Route path="/tution" element={<Tuition />} />
          <Route path="/account" element={<AccountingServices />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
